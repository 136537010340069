import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const Golang = () => {
  return (
    <Layout>
      <Vacancy
        division="Development"
        vacancy="Go (Golang, Google Go) Developer"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            Our backend team is looking for Go (Golang, Google Go) developers who can quickly join in, create quality
            code, contribute to discussions, and be eager to learn and grow.
          </p>
        }
        jobResponsibilities={
          <ul>
            <li className="secondary-text">Write a clean and optimized code using Go</li>
            <li className="secondary-text">Develop new features and improve existing features of the software</li>
            <li className="secondary-text">
              Contribute to team and organizational improvements in process and infrastructure.
            </li>
          </ul>
        }
        qualifications={
          <ul>
            <li className="secondary-text">Provable proficiency in Go programming</li>
            <li className="secondary-text">Knowledge of Go templating, common frameworks, and tools</li>
            <li className="secondary-text">Experience with NodeJS</li>
            <li className="secondary-text">Professional experience with Git</li>

            <li className="secondary-text">
              Familiarity with the whole web stack, including protocols and web server optimization techniques
            </li>
            <li className="secondary-text">Attention to detail.</li>
          </ul>
        }
      />
    </Layout>
  );
};

export default Golang;
